body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #FFF0ED ;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.nav-bar {
  width: 100%;
  height: 80px;
  background-color: #FFEFCF ;
}

.navImage-Container {
  background-color: #FFE5E0 ;
  height: 300px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}


.navText {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  font-weight: bold;
  font-size: 30px;
  font-style: italic;
  font-family: 'Dancing Script', cursive;
  color: black;
}

.navLogo {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
   height: 100px;
   width: 100px;
}

.navText-1 {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  font-family: 'Dancing Script', cursive;
  color: black;
}

.block-1 {
  background-color: white ;
  width: 600px;
  height: 600px;
  position: relative;
  top: 400px;

}
.block-2 {
  background-color: #FFF0ED ;
  width: 100%;
  height: 600px;
  border-radius: 1%;
  position: relative;
  top: 400px;
  bottom: 400px;
}


.footer {
  width: 100%;
  height: 270px;
  top: 150px;
  position: relative;
}

.footerText {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  font-weight: bold;
  font-size: 35px;
  font-style: italic;
  font-family: 'Dancing Script', cursive;
  color: black;
}

.contactText {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 20px;
  font-size: 15px;
  color: black;
  text-decoration: none
}
.contactContainer {

  
  margin: 50px auto;
  width: 150px;
  height: 75px;
  font-size: 90px;
  /* line-height: 150px; */
  transition: .3s ease;
}

.contactContainer:hover {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
}

.socialMediaImg {
  position: relative;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 20px;
  width: 30px;
  height: 30px;
}

.socialMediaSpacer {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
}

.socialMediaContainer {
  position: relative;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 20px;
  width: 50px;
  height: 50px;
}

.mapContainer {
  position: relative;
  top: 30px;
  margin-left: 30%;
  bottom: 20px;
  width: 40%;
  height: 150%;
}


.shadow {
  box-shadow: 1px 1px gray;
}

.padding-1 {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.padding {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1%;
  margin: auto;    
  display: block;
  }

.img1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1%;
  }

  .no-padding {
    padding-left: 0;
    padding-right: 0;
  }

.col {
  
  background-color: #FFEFCF;
}
.col-two {
  background-color: #FFEFCF;
}

.col-text {
  font-weight: bold;
  font-size: 40px;
  font-style: italic;
  font-family: 'Dancing Script', cursive;
  color: black;
}

.col-text-bio {
  font-weight: bold;
  font-size: 20px;
  font-style: italic;
  margin-top: 20px;
  text-align: left;
  font-family: 'Dancing Script', cursive;
  color: black;
}


.col-text-1 {
  font-size: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  text-align: left;
  margin: auto;
}

.col-border {
  border-style: groove;
}
.line {
  width: 80%;
  left: 0;
  position: relative;
}

.col-body {
  background-color: white;
}
body{
  background-color: #FFEFCF !important;
}

.bookNowButton {
  background-color: #FFEFCF;
  border: 2px solid black;
  border-radius: 15px;
  color: #333;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 20px;
  right: 10px;
}

.bookNowButton:hover {
  background-color: #cccccc;
}

.bookNowButtonBottom {
  background-color: #FFEFCF;
  border: 2px solid black;
  border-radius: 15px;
  color: #333;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 5px;
  transition: background-color 0.3s ease;
  
}

.bookNowButtonBottom:hover {
  background-color: #cccccc;
}
